import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

// contexts
import { apiContext } from "../api-provider/ApiProvider";
import { currencyContext } from "../currency-provider/CurrencyProvider";

// consts
import { CART } from "../cart-provider/CartProvider.consts";
import {
  API_URL_MARKET,
  API_URL_MARKET_SKIN_PRICE,
} from "./SkinProvider.consts";

// schemas
import { allSkinsSchema, cartSkinsPriceSchema } from "./SkinProvider.schemas";

// types
import { updatedSkinSchema } from "../order-provider/OrderProvider.schemas";
import type {
  CurrencyFromApiModel,
  FiltersSkinFormType,
  SkinContext,
  SkinProviderProps,
} from "./SkinProvider.types";

export const skinContext = React.createContext({} as SkinContext);

export const SkinProvider: FunctionComponent<SkinProviderProps> = (props) => {
  const { api } = useContext(apiContext);
  const { getCurrency } = useContext(currencyContext);

  const { children } = props;

  const [skins, setSkins] = useState<any[] | null>(null);
  const [rareSkins, setRareSkins] = useState<any[] | null>(null);
  const [total, setTotal] = useState(0);
  const [currencyEurValue, setCurrencyEurValue] = useState(0);

  const getSkins = async (
    filtersData: FiltersSkinFormType,
    limit?: number,
    offset?: number
  ) => {
    try {
      const currentCurrency = getCurrency();
      const apiURL = `${API_URL_MARKET}?limit=${limit ? limit : 1000}${
        offset !== undefined ? `&offset=${offset}` : ""
      }${filtersData.name ? `&filter[search]=${filtersData.name}` : ""}${
        filtersData.quality ? `&filter[quality_id]=${filtersData.quality}` : ""
      }${
        filtersData.exterior
          ? `&filter[exterior_id]=${filtersData.exterior}`
          : ""
      }${
        filtersData.category
          ? `&filter[category_id]=${filtersData.category}`
          : ""
      }${filtersData.type ? `&filter[type_id]=${filtersData.type}` : ""}${
        filtersData.price_from
          ? `&filter[priceMin]=${`${Number(filtersData.price_from)}`}`
          : ""
      }${
        filtersData.price_to
          ? `&filter[priceMax]=${`${Number(filtersData.price_to)}`}`
          : ""
      }&currency=${currentCurrency}&sort[updatedAt]=DESC`;

      const allSkins = await api(apiURL, {}, allSkinsSchema);

      if (allSkins) {
        const filteredSkins = allSkins.data.filter((skin) => skin.price);

        setSkins(filteredSkins);
        setTotal(allSkins.total);
      }
    } catch (error) {
      throw error;
    }
  };

  const getSkinById = async (skinId: string) => {
    try {
      const currentCurrency = getCurrency();
      const apiURL = `${API_URL_MARKET}/${skinId}?currency=${currentCurrency}`;

      const skin = await api(apiURL, {}, updatedSkinSchema);

      return skin;
    } catch (error) {
      throw error;
    }
  };

  const getRareSkins = async () => {
    try {
      const currentCurrency = getCurrency();

      const apiURL = `${API_URL_MARKET}?limit=100&currency=${currentCurrency}`;

      const rareSkins = await api(apiURL, {}, allSkinsSchema);

      if (rareSkins) {
        const filteredRareSkins = rareSkins.data.filter((skin) => skin.price);

        setRareSkins(filteredRareSkins);
      }
    } catch (error) {
      throw error;
    }
  };

  const checkCartSkinPrice = async () => {
    try {
      const currentCurrency = getCurrency();

      const cartString = localStorage.getItem(CART);

      const cart: string[] = cartString ? JSON.parse(cartString) : [];

      const skinsIds = cart.map((skin) => skin);

      if (cart.length) {
        const skinPrices = await api(
          `${API_URL_MARKET_SKIN_PRICE}?currency=${currentCurrency}`,
          { method: "POST", data: { ids: skinsIds } },
          cartSkinsPriceSchema
        );

        if (skinPrices) {
          return skinPrices.skins;
        }
      }
      return null;
    } catch (error) {
      throw error;
    }
  };

  const fetchCurrencyRates = async () => {
    try {
      const response = await fetch(
        "https://bank.gov.ua/NBUStatService/v1/statdirectory/exchange?json"
      );
      const json = await response.json();

      const currency = json.filter(
        (currency: CurrencyFromApiModel) =>
          currency.cc === "USD" || currency.cc === "EUR"
      );

      const result: any = {};
      currency.forEach((cur: CurrencyFromApiModel) => {
        result[cur.cc] = cur.rate;
      });

      return result.USD / result.EUR;
    } catch (err) {
      console.warn(err);
      alert("Failed to get exchange rates from the server");
      return null;
    }
  };

  useEffect(() => {
    const getRates = async () => {
      const eurValue = await fetchCurrencyRates();
      if (eurValue) {
        setCurrencyEurValue(eurValue);
      }
    };

    getRates();
  }, []);

  const contextValue = useMemo(
    () => ({
      skins,
      rareSkins,
      total,
      currencyEurValue,
      getSkins,
      getSkinById,
      getRareSkins,
      checkCartSkinPrice,
    }),
    [
      skins,
      rareSkins,
      total,
      currencyEurValue,
      getSkins,
      getSkinById,
      getRareSkins,
      checkCartSkinPrice,
    ]
  );

  return (
    <skinContext.Provider value={contextValue}>{children}</skinContext.Provider>
  );
};
